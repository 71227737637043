import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import Claim from '../components/Claim'
import StyledContainer from '../components/Styled'
import useMobile from '../hooks/useMobile'
import styled from 'styled-components'

const ScrollInside = styled(StyledContainer)`
    // max-height: 100vh;
    // overflow-y: scroll;

`
const Button = styled.button`
    background: #6425C5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 206px;
    height: 52px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top:10px;
`
const Text = styled.text`
    font-family: GT Eesti Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom:10px;
    text-align:justify;

`
const Modal = styled(StyledContainer)`
   // transition: transform linear .2s;
  //  transform: ${({ show, ifMobile }) => (ifMobile ? (show ? 'translateY(0%)' : 'translateY(100vh)') : 'none')};
   position:fixed;
   display: ${({ show }) => (show ? 'block' : 'none')};
   z-index:20;
   top:100px;
   left:0;
   right:0;
   width:360px;
   margin:0 auto;
   padding:24px;
   
`

const FullHeight = styled(StyledContainer)`
    // overflow: hidden;
    // height: 100vh;
`

export default () => {
    const ifMobile = useMobile()
    const [show, setShow] = useState(false)
    const closeModal = () => {
        setShow(false)
    }
    const showModal = () => {
        setShow(true)
    }

    return (
        <div style={{ position: "relative" }}>


                <StyledContainer flex column justifyContent="space-between" height="100vh" overflow='hidden' position='relative' width='100%'  background='linear-gradient(180deg, #1F1831 0%, #2D214D 100%)'>
                    <Claim ifMobile={ifMobile} />
                    <Footer ifMobile={ifMobile} />
                </StyledContainer>
        </div>

    )
}