import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Claim from './view/Claim';
import './assets/fonts/index.css'
import StyledContainer from './components/Styled';
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'


function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000
  return library
}

const Web3ProviderNetwork = createWeb3ReactRoot('NETWORK')

function App() {
  return (
    <Web3ReactProvider
      getLibrary={getLibrary}
    >
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Router>
          <div className="App">
              <Switch>
                <Route path='/'>
                  <Claim/>
                </Route>
              </Switch>

          </div>
        </Router>
      </Web3ProviderNetwork>

    </Web3ReactProvider>

  );
}

export default App;
