
const IFMAINNET = process.env.REACT_APP_ENV === 'MAIN'

export const baseChainID = IFMAINNET ? 43114 : 43113;
// export const baseChainID = 43114;
// console.log('baseChainID',process.env.REACT_APP_ENV,baseChainID)

export const HCT = IFMAINNET ?
  {
    address: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    symbol: 'HCT',
    decimals: 18,
    network:'main'
  } :{
    address: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    symbol: 'HCT',
    decimals: 18,
    network:'testnet'
  }
  // 0x37114ef749a1e17cb01f7769975fF569bC47bA8F  12-10
  // 0x90E97976592aBa2f5C5c54Bd9ed1a797D84060c1  2-10
  export const ContractAddress = IFMAINNET ?'0x37114ef749a1e17cb01f7769975fF569bC47bA8F':''