import React, { useState, useEffect } from 'react'
import { StyledButton,StyledDisabledButton, Image, ImageLine } from './Preset'
import StyledContainer from './Styled'
import styled from 'styled-components'
import Overlay from './Overlay'
// import useRequest from '@ahooksjs/use-request'
import { useWeb3React } from '@web3-react/core'
import { parseEther, formatEther } from '@ethersproject/units'

import useAuth from '../hooks/useAuth'
import { useTokenContract,useContract } from '../hooks/useContract'
import { useTimer } from 'react-timer-hook';
import CheckTransactionStatus from './CheckTransactionStatus'
import Loading from './Loading'
import { HCT } from '../constants'
import ContractABI from '../constants/abis/financing.json'

const testABI =[{"inputs":[],"name":"claim","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256","name":"account","type":"uint256"}],"name":"releaseTasks","outputs":[{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256","name":"totalAmountT","type":"uint256"},{"internalType":"uint256","name":"stepAmountT","type":"uint256"},{"internalType":"uint256","name":"releasedAmountT","type":"uint256"},{"internalType":"uint256","name":"nextReleaseTimeT","type":"uint256"}],"name":"setReleaseTask","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"uint256","name":"num","type":"uint256"}],"name":"store","outputs":[],"stateMutability":"nonpayable","type":"function"}]
const addrList = require('../assets/financing.json')
const firstRoundList = Object.keys(addrList['1st']).map(item=>item=item.toLowerCase());
const secondRoundList = Object.keys(addrList['2nd']).map(item=>item=item.toLowerCase());



const Title = styled(StyledContainer)`
    position: relative;
    ::after{
        content: '';
        display: inline-block;
        position: absolute;
        // top: -30px;
        top: -.8em;
        left: -20px;
        // width: 54.43px;
        // height: 10.89px;
        width: 1.2em;
        height: .3em;
    }
`
const BtnWrap = styled(StyledContainer)`
    display:flex;

`
const ClaimBtn =styled(StyledContainer)`
display:flex;

`

const ArrowIcon = () => {
  return (<svg style={{ verticalAlign: "middle" }} width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.54102 12.6133H24.8575" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.6992 5.61328L24.8574 12.6133L15.6992 19.6133" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  )
}

const contractAddress = process.env.REACT_APP_USDT_E_CONTRACT_ADDRESS
const recipientAddress = process.env.REACT_APP_RECIPIENT

export default ({ ifMobile }) => {
    const [ifShow, setShowModal] = useState(false)
    // const [address, setAddress] = useState(null)
    const [isClaimed, setIsClaimed] = useState(false)
    const [claimStatus, setClaimStatus] = useState(false)
    const [ifLoading, setIfLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [is1st,setIs1st] = useState(false)
    const [is2nd,setIs2nd]=useState(false)
    const [canClaim1stRound,setCanClaim1stRound]=useState(false)
    const [canClaim2ndRound,setCanClaim2ndRound]=useState(false)
    const [if1stLoading,setIf1stLoading]=useState(false)
    const [if2ndLoading,setIf2ndLoading]=useState(false)
    const [claim1stStatus, setClaim1stStatus] = useState(false)
    const [claim2ndStatus, setClaim2ndStatus] = useState(false)


    const { ethereum } = window 

    // const tokenContract = useTokenContract(contractAddress)
    const airdropContract1st = useContract('0x90E97976592aBa2f5C5c54Bd9ed1a797D84060c1',ContractABI)
    const airdropContract2nd = useContract('0x37114ef749a1e17cb01f7769975fF569bC47bA8F',ContractABI)
    
    // const airdropContract1st = useContract('0x8EA91AD2B307e8581596BF185d5E2a241695c0f6',testABI)
    // const airdropContract2nd = useContract('0x412bBb1aC817AD17Af439262350b9CBC1De20180',testABI)
    const { account } = useWeb3React()
    const { login } = useAuth()
    
    useEffect(() => {
        if (account) {
            closeModal()
            setIfLoading(false)
            setErrorMsg('')
            checkAccoutStatus(account)
        }else{
          resetState()
        }
    }, [account])


    const closeModal = () => {
        setErrorMsg('')
        setShowModal(false)
        setIf1stLoading(false)
        setIf2ndLoading(false)
    }
    const showModal = () => {
        setShowModal(true)
    }

    const connectWallet = async () => {
      if (typeof window.ethereum == 'undefined') {
        setErrorMsg('No wallet plugin is detected, please install first！')
        setShowModal(true)
      }
        if (ifLoading) {
            return;
        }
        if (!account) {
            setIfLoading(true)
            login()
            return;
        }      
    }
    const checkAccoutStatus=(account)=>{
      if (account) {
        setIfLoading(true)
        if(!addrList){
          setIfLoading(false)
          return;
        }
        // console.log(addrList)
        const accountWithout0x =account.replace("0x",'')
        const check1stList = firstRoundList.includes(accountWithout0x.toLowerCase())
        const check2ndList = secondRoundList.includes(accountWithout0x.toLowerCase())
        setIs1st(check1stList)
        setIs2nd(check2ndList)
        // console.log(check1stList,check2ndList)
      } else {
        // 没连钱包
        resetState();
      }
    }

    useEffect(()=>{
      // console.log('account',account)
      if(!account){
        return 
      }
      /*
        [0]uint256 totalAmount  总额度
        [1]uint256 stepAmount   每次release的额度
        [2]uint256 releasedAmount 已经释放的额度
        [3]uint256 nextReleaseTime  下次release时间
      */
     const now = (new Date().getTime())/1000
      if(is1st){
        airdropContract1st.releaseTasks(account).then(release=>{
          // console.log('release',release)
          // [nextReleaseTime,releasedAmount,stepAmount,totalAmount]
          // console.log('nextReleaseTime',release[0].toString())
          // console.log('stepAmount',release[1].toString())
          // console.log('releasedAmount',release[2].toString())
          // console.log('nextReleaseTime',release[3].toString())
          const totalAmount = release[0]
          const stepAmount =release[1]
          const releasedAmount =release[2]
          const nextReleaseTime =release[3].toString()
          const maxRelease = stepAmount.mul(12)
          if(totalAmount.gt(0) && now>=nextReleaseTime && releasedAmount.lt(maxRelease)){
            setCanClaim1stRound(true)
          }else{
            setCanClaim1stRound(false)
          }
        })
      }
      if(is2nd){
        airdropContract2nd.releaseTasks(account).then(release=>{
          // console.log('release',release)
          // [nextReleaseTime,releasedAmount,stepAmount,totalAmount]
          // console.log('nextReleaseTime',release[0].toString())
          // console.log('stepAmount',release[1].toString())
          // console.log('releasedAmount',release[2].toString())
          // console.log('nextReleaseTime',release[3].toString())
          const totalAmount = release[0]
          const stepAmount =release[1]
          const releasedAmount =release[2]
          const nextReleaseTime =release[3].toString()
          const maxRelease = stepAmount.mul(12)
          if(totalAmount.gt(0) && now>=nextReleaseTime && releasedAmount.lt(maxRelease)){
            setCanClaim2ndRound(true)
          }else{
            setCanClaim2ndRound(false)
          }
        })
      }
    },[is1st,is2nd,account])

    const resetState =()=>{
      setIsClaimed(false)
    }
   
    const ActionClaimHCT =(roundNo)=>{
      // console.log('ActionClaimHCT')
      let claimContract = airdropContract1st
      if(roundNo==='1st'){
        setIf1stLoading(true)
      }else{
        setIf2ndLoading(true)
        claimContract=airdropContract2nd
      }
   
      claimContract.claim().then(claimed=>{
        // console.log('-----claimed----',claimed)
        setClaimStatus(true)
        setShowModal(true)
        if(roundNo==='1st'){
          setIf1stLoading(false)
          setIs1st(false)
          setClaim1stStatus(true)
        }else{
          setIf2ndLoading(false)
          setIs2nd(false)
          setClaim2ndStatus(true)
        }
      },error=>{
       let message = error?.message;
        if(error?.data){
          message = error?.data.message;
        }
        setErrorMsg(message)
        setShowModal(true)
      })
    }
    const addHCT2Wallet = () => {
      if (ethereum) {
        ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: HCT?.address,
              symbol: HCT?.symbol,
              decimals: HCT?.decimals,
              image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11885.png',
            },
          },
        }).catch((error) => {
          console.error(error)
        })
      }
    }
    useEffect(() => {
        if (ifShow) {
            setIfLoading(false)
        }
    }, [ifShow])
    return (
            <>
                <StyledContainer className="claim-container">
                      <StyledContainer className="page-title-block">
                        <Title fontWeight='bold' fontSize='3em' color='#fff'>HurricaneSwap Private Sale </Title>
                        <StyledContainer mt='10px' mb='10px' fontWeight='bold' fontSize='4.5em' color='#fff'>Claim</StyledContainer>
                      </StyledContainer>
                      <StyledContainer mt='35px' className="btn-block" maxWidth="640px">
                          {
                              account  && (
                                  <StyledContainer mb='24px' color='#fff' >
                                      <span className="your-address">Your address : </span><span className="wallet-address" >{account}</span>
                                  </StyledContainer>
                              )
                          }
                          {account && 
                          <StyledContainer pointer flex style={{margin:"0 -10px ",}}>  
                                  {canClaim1stRound &&(claim1stStatus ?<StyledDisabledButton  style={{flexGrow:1,margin:"0 10px",fontSize:"20px"}}>1st Round Claim Success</StyledDisabledButton> :<StyledButton style={{flexGrow:1,margin:"0 10px "}}  onClick={()=>ActionClaimHCT('1st')} >
                                        {if1stLoading ? <Loading></Loading>:`1st Round`  } 
                                      </StyledButton>)
                                  }
                                  {
                                    canClaim2ndRound &&(claim2ndStatus ?<StyledDisabledButton  style={{flexGrow:1,margin:"0 10px ",fontSize:"20px"}}>2nd Round Claim Success</StyledDisabledButton> :<StyledButton style={{flexGrow:1,margin:"0 10px"}} onClick={()=>ActionClaimHCT('2nd')} >
                                    {if2ndLoading ? <Loading></Loading>:`2nd Round`  } 
                                  </StyledButton>)
                                  }
                                  {( !canClaim1stRound && !canClaim2ndRound) &&<StyledDisabledButton style={{flexGrow:1,margin:"0 10px "}}>No allocation</StyledDisabledButton> }
                          </StyledContainer>
                          }
                          <StyledContainer pointer  >    
                          {!account &&
                                  <StyledButton onClick={connectWallet} >
                                    {ifLoading ? <Loading></Loading>:`Connect your wallet`  }  
                                  </StyledButton>
                                }
                          </StyledContainer>
                          <StyledContainer  mb='22px'    >
                              <a href="https://hurricaneswap.com/" target="_blank">
                                  <StyledButton padding='2px'>
                                      <StyledContainer borderRadius='100px' width='100%' height='100%' flex alignItems='center' justifyContent='center' background='#191329'>HurricaneSwap <ArrowIcon /></StyledContainer>
                                  </StyledButton>
                              </a>
                          </StyledContainer>
                          <StyledContainer  mb='22px'  >
                                <StyledButton  pointer padding='2px' onClick={addHCT2Wallet}>
                                    <StyledContainer borderRadius='100px' width='100%' height='100%' flex alignItems='center' justifyContent='center' background='#191329'>Add HCT to Wallet</StyledContainer>
                                </StyledButton>
                          </StyledContainer>
                      </StyledContainer>
                </StyledContainer>
            <Overlay dismiss={closeModal} show={ifShow} setShow={setShowModal}>
                <CheckTransactionStatus errorMsg={errorMsg} status={claimStatus}></CheckTransactionStatus>
            </Overlay>
        </>
    )
}